import { v4 as uuid } from 'uuid'
import { ExpensePhoto } from './expense-photo.model'

export class ExpenseAndPhoto {
  expense_id: number
  photo: ExpensePhoto
}

export class ExpenseLine {
  uuid: string
  id: number
  expense_report_id: number
  area_worked_id: number
  expense_category_id: number
  expense_category: string
  is_pending_save: boolean
  is_pending_delete: boolean
  is_billable: boolean
  date_incurred: string
  date_incurred_multiple: string[] = []
  work_order_id: number
  po_number?: string
  quantity: number
  price: number
  total: number
  has_receipt: boolean
  photo: ExpensePhoto | undefined
  is_photo_pending_save: boolean
  is_photo_pending_delete: boolean
   
  constructor() {
  }

  public async setViewModelFields()
  {
    if (!this.id) {
      this.id = 0
    }
    if (this.uuid === undefined) {
      this.uuid = uuid();
    }
    if (!this.total) {
      if(Number(this.expense_category_id) === 7) { // Per Diem
        this.total = this.price
      } else {
        this.total = this.price * this.quantity
      }
    }
    if (this.is_pending_save === undefined) {
      this.is_pending_save = false
    }
    if (this.is_pending_delete === undefined) {
      this.is_pending_delete = false
    }
    if (this.is_photo_pending_save === undefined) {
      this.is_photo_pending_save = false
    }
    if (this.is_photo_pending_delete === undefined) {
      this.is_photo_pending_delete = false
    }
  }

  static buildOne(obj: any): ExpenseLine {
    let target: ExpenseLine = Object.assign(new ExpenseLine(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): ExpenseLine[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(ExpenseLine.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

