import { Photo } from './photo.model'
import { v4 as uuid } from 'uuid'

export class FormPhoto extends Photo {
  // Model
  form_id = 0
  index = 0
  is_photo_pending_save: boolean = false
  is_photo_pending_delete: boolean = false

  public async setViewModelFields()
  {
    if (this.photo_data && this.photo_type) {
      this.base64 = 'data:' + this.photo_type + ';base64,' + this.photo_data
      this.base64_sz = this.base64
      this.photo_data = null
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
  }

  constructor(
   ) {
    super()
  }

  static override async buildOne(obj: any): Promise<FormPhoto> {
    let target: FormPhoto = Object.assign(new FormPhoto(), obj)
    await target.setViewModelFields()
    return target
  }

  static override async buildMany(objs: any): Promise<FormPhoto[]> {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(await FormPhoto.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

