import { Injectable, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import * as myGlobals from '../globals';
import { DateTime } from 'luxon';
import {AppInfoService} from './app-info.service';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { Select } from '@ngxs/store'
import { UserState, UserStateModel } from 'src/app/state-man/state/user.state';
import { Observable } from 'rxjs';
import { User } from 'src/app/state-man/models/user.model';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class ServerVersionService {
  @Select(UserState.getUser) user$: Observable<User>
  events: any;
  user: User;
  page_strings: any;

  constructor (
      private appInfoService: AppInfoService,
      private apiService: ApiService,      
      private uiService: UiService
  ) {
    this.user = UserStateModel.getDefaultUser()
    this.page_strings = {
      update_message_title: myGlobals.welcome_update_message_title,
      update_message: myGlobals.welcome_update_message
    };
    this.events = new Subject();
  }

  startWatching() {
    this.user$.subscribe((val: User) => {   
      this.user = val      
    })
    this.setVersionCheckTimer();
  }

  setVersionCheckTimer() {
    setTimeout(() => {
      this.checkAppVersion();
    }, myGlobals.welcome_check_version_timeout_ms);
  }

  async checkAppVersion() {
    // Need to be logged in to check versions, if not, reset timer and return.    
    if (!this.user.is_logged_in) {
      this.setVersionCheckTimer()
      return;
    }

    try {
      const localAppVersion = this.getLocalAppVersion()
      const serverVersion = await this.getServerVersion()
      if ((serverVersion !== 'UNKNOWN') && (localAppVersion !== serverVersion)) {        
        //this.promptToDownloadNewVersion()
      } else {        
        this.setVersionCheckTimer()
      }
    } catch (error) {      
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      this.setVersionCheckTimer()
    }  
  }

  getLocalAppVersion() {
    return myGlobals.app_version_number;
  }

  async getServerVersion() {
    let serverVersion = 'UNKNOWN';
    const api_results = await this.appInfoService.getAll();
    if (api_results) {
      const element = api_results.find(function(element: any) {
        return element.key === 'version';
      });
      if (element) {
        serverVersion = element.value;
      }
      this.events.next(DateTime.now().toFormat('MMM dd yyyy, h:mm:ss a'));
    }
    return serverVersion;
  }

  async promptToDownloadNewVersion() {
    const retVal = await this.uiService.confirmPrompt(this.page_strings.update_message_title, this.page_strings.update_message, "OK", '')
    console.log('Prompted to download new version, retVal:', retVal);
    if (retVal) {
      console.log('Agreed to download new version');
      this.apiService.goHome();
    } else {
      console.log('Prompted to download new version, but user declined.  Too bad, doing it anway.');
      this.apiService.goHome();
      //this.setVersionCheckTimer();
    }
  }
}
