import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  { path: "", redirectTo: "welcome", pathMatch: "full" },
  {
    path: "",
    loadChildren: () =>
      import("./pages/welcome/welcome.module").then((m) => m.WelcomePageModule),
  },
  {
    path: "tabs",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
      path: "forgot-password",
      loadChildren: () => import("./pages/forgot-password/forgot-password.module").then((m) => m.ForgotPasswordPageModule),
  },
  {
      path: "login-verify",
      loadChildren: () => import("./pages/login-verify/login-verify.module").then((m) => m.LoginVerifyPageModule),
  },
  {
      path: "reset-password/:id",
      loadChildren: () => import("./pages/reset-password/reset-password.module").then((m) => m.ResetPasswordPageModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: 'pto-page',
    loadChildren: () => import('./pages/pto-page/pto-page.module').then(m => m.PtoPagePageModule)
  },
  {
    path: 'base-ist-form',
    loadChildren: () => import('./pages/base-ist-form/base-ist-form.module').then( m => m.BaseIstFormPageModule)
  },
];
@NgModule({
  imports: [
    NgSelectModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
